

jQuery(document).ready(function( $ ) {
	
$('.variations_form').each(function(){
$(this).wc_variation_form();
});
	
});



