document.addEventListener('DOMContentLoaded', () => {


    /////window.Popper || console.log("no popper");


	const showClass = "show";
	happyScrolling();
    console.log("Welcome");


    window.addEventListener('scroll', function() {
        throttleScroll(happyScrolling, 100);
    });

    replaceBackstretch();

    let brandFilterItems = document.querySelectorAll('.wpf_item.wpf_item_pwb-brand li');
    brandFilterItems.forEach((brandItem)=>{
        let brandInput = brandItem.querySelector('input');
        let brandLabel = brandItem.querySelector('label');
        
        console.log("brandLabel ",brandLabel.innerHTML);

        let brandImage = brandLabel.style.backgroundImage;

        if(brandInput && brandLabel && brandImage) {

            let brandImageSrc = brandImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0];
            if (brandImageSrc && brandImageSrc.length > 0) {
                brandLabel.style.removeProperty('background');
                //brandLabel.style.backgroundImage = 'url('+brandImageSrc+')';
            
                var img = new Image();
                img.src = brandImageSrc;
                brandLabel.insertBefore(img, brandLabel.firstChild);
            }
        }
        

    });


    let accordionItems = document.querySelectorAll('#shop-sidebar-categories .accordion-item');
    accordionItems.forEach((accordionItem)=>{
        let accordionButton = accordionItem.querySelector('.accordion-button');
        var clicker = understrap.Collapse.getInstance(accordionButton);
        let accordionCollapse = accordionItem.querySelector('.accordion-collapse');
        if(accordionButton && accordionCollapse && clicker) {
            accordionButton.addEventListener('click', function (e) {
                console.log("click", accordionButton.classList );            
                
                if(accordionButton.classList.contains('collapsed')) {
                    /* item should be collapsed */
                } else {
                    //e.stopPropagation();
                    //accordionCollapse.classList.remove('show');
                    //accordionButton.classList.add('collapsed');
                    //clicker.toggle();
                }
            });
        }
    });

    let dds = document.querySelectorAll('#megamenu .menu-item')
    dds.forEach((dd)=>{
        dd.addEventListener('mouseover', function (e) {
            dd.classList.add('show');
        });
        dd.addEventListener('mouseout', function (e) {
            dd.classList.remove('show');
        })
    });

});

function replaceBackstretch() {
    let backstretchWrapper = document.querySelector('.backstretch-wrapper');
    if(backstretchWrapper) {
        let backstretcher = document.querySelector('.backstretch-container');
        let img = backstretcher ? backstretcher.getAttribute('data-img') : null;
        if(img) {
            backstretchWrapper.style.backgroundImage = 'url('+img+')';
        }
    }
}

function happyScrolling() {
    return;
    let header  = document.querySelector('header');
    let footer = document.querySelector('footer');
    let page = document.querySelector("#page");
    let rect = page.getBoundingClientRect();
    //let tiptop = document.querySelector("#tippitoppi");
    //console.log("slimslim "+rect.y);
    if(rect.y < 0) {
        header.classList.add("slimslim");
      //  tiptop.classList.remove("hide");
    } else {
        header.classList.remove("slimslim");
        //tiptop.classList.add("hide");
    }
    

    var h = document.documentElement,
    b = document.body;
    var scroll = (h["scrollTop"] || b["scrollTop"]) / ((h["scrollHeight"] || b["scrollHeight"]) - h.clientHeight) * 100;


    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;    
//console.log("scrollTop ",scrollTop);
    let stretcher       = document.querySelector('.backstretch-wrapper');
    let happyDivUp      = stretcher? stretcher.querySelector('.happy-scrolling-up') : null;   
    
    if( window.innerWidth < 1000) {

        if(stretcher && happyDivUp) {

            happyDivUp.classList.add('show');
            happyDivUp.style.transform = 'translateY(5rem)'//;'none';
            stretcher.style.marginBottom = 'auto';
        }

        
    } else {

        if(stretcher){
            let topPosition = stretcher.getBoundingClientRect().top;
            if (topPosition > 0) {
                stretcher.classList.add('fixed');
            } else {
                stretcher.classList.remove('fixed');
            }
        }

        if(stretcher && happyDivUp) {
            let stretcherHeight = stretcher.offsetHeight;
            let stretcherTotalHeight = stretcher.scrollHeight + stretcherHeight;
            let happyHeight = happyDivUp.offsetHeight;
            if (scrollTop > 80) {
                happyDivUp.classList.add('show');
                let moveTo = stretcherHeight - happyHeight / 2;
                if( moveTo < footer.offsetTop) {
                    happyDivUp.style.transform = 'translateY(' + (moveTo) + 'px)';
                    stretcher.style.marginBottom = happyHeight + 'px';
                }
            } else {
                happyDivUp.classList.remove('show');
                happyDivUp.style.transform = 'translateY(' + (stretcherHeight + happyHeight * 2) + 'px)';
            }
        }
    }

    let happyDivStretch = stretcher? stretcher.querySelector('.happy-scrolling-stretch') : null;   
    if(stretcher && happyDivStretch) {

        let covers = document.querySelectorAll('.happy-scrolling-stretch .wp-block-cover:last-child');
        
        let stretcherHeight = stretcher.offsetHeight;
        let happyHeight = happyDivStretch.offsetHeight;

        if( window.innerWidth < 1000) {
            if( covers ) {
                let coverShift = covers[0].offsetHeight / 2;
                stretcher.style.marginBottom = 'auto';
                happyDivStretch.style.transform = 'translateY(-'+ coverShift +'px)';           
            }
            
        } else {

            stretcher.style.marginBottom = happyHeight/2 + 'px';
            //stretcher.style.marginTop = (happyHeight/4 - scrollTop)   + 'px';

            let marginCover = scrollTop < 300 ? 300 - scrollTop : 0;//happyHeight / 2 * (1 - scroll/100);

            covers.forEach( (element, index) => {
                element.style.transform = 'matrix(1, 0, 0, 1, 0, '+marginCover+')';
            });


            console.log("stretcher & div height",stretcherHeight,' ',happyHeight, ' scrolltop ', scrollTop);

            if(scrollTop < happyHeight ){
                let spacer300 = scrollTop -  300 *  scroll / 100;
                happyDivStretch.style.transform = 'translateY(' + ( spacer300 - happyHeight / 6 ) + 'px)';           
            }
        }
    }



    /*************************************
     * Happy Scrolling
     */
    return;

    let happyDiv = document.querySelector('.happy-scrolling');   
    
    var windowHeight = window.innerHeight;
    var topper = 100;// windowHeight * 0.05;
    if (window.innerWidth < 768) {
        topper = 80;
    }
    let moveTo = scrollTop;//+ topper;//scrollTop^2*0.05 + 100 + topper;
    
    if(happyDiv) {
        happyDiv.style.transform = 'translateY(' + topper + 'px)';
    }

    let happyGaps = document.querySelectorAll('.happy-scrolling-gap');
    if(happyGaps) {

        

        var h = document.documentElement,
            b = document.body;
        var scroll = (h["scrollTop"] || b["scrollTop"]) / ((h["scrollHeight"] || b["scrollHeight"]) - h.clientHeight) * 100;
        console.log("scroll ",scroll);


        let marginTop = (100 - scroll) * 3;

/* Am Ende angekommen?
        if (Math.round(window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
         
            marginTop = 0;
        }
*/
        happyGaps.forEach( (element, index) => {
            //element.style.marginTop = marginTop + 'px';
            element.style.transform = 'matrix(1, 0, 0, 1, 0, '+marginTop+')';
        });
    }


}

let throttlePause;
const throttleScroll = (callback, time) => {
    if (throttlePause) return;
    throttlePause = true;
    setTimeout(() => {
        callback();
        throttlePause = false;
    }, time);
};

